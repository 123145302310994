
.price-title {
    color: #282828;
  font-size: 30px;
  /* padding-bottom: 30px; */
  line-height: 28px;
  font-weight: 400;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 18px;
  width: 100%;
  }

  .content-container {
    display: grid;
    grid-template-columns: 1fr 1fr ;
  grid-template-areas: 'col1 col2 ';
  }

  .itemlist-container{
    grid-area: col1 ;
    width: 300px;
    padding-left: 8%;
    
  }


  .pricelist-container{
    grid-area: col2 ;
    text-align:right;
  }

  .price-item-header{
    font-size: 16px;
    margin-top: 12px;
  }

  .price-item{
    margin-top:6px;
    font-size: 14px;

  }
  
  .title-wrapper {
    border-bottom: 1px solid #282828;
    margin: 0 2%;
    margin-bottom: 30px;
  /* margin-top: 50px; */
  }
  
  .price-container {
      padding-top: 120px;
      max-width: 100% !important;
      height: auto;
      background: snow;

  }


  
  @media screen and (max-width: 480px) {
   
    
    .price-container{
      display: none !important;
    }
  

};
