
*{
box-sizing: border-box;
margin: 0;
padding: 0;
font-family: 'Playfair Display' serif;
object-fit: cover;
}

#project-container {
position: relative;
z-index: 1;
overflow-x: hidden;
object-fit: cover;
}