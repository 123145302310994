.MuiTab-textColorPrimary.Mui-selected {
    color: #016379 !important;
}

.MuiAppBar-colorDefault {
    background-color: snow!important;
}

.PrivateTabIndicator-root-5 {
    display:none !important;
}

.pricelist-mobile-container{
   padding-left: 1%;
   padding-right:1%;
    padding-top: 120px;
    height: auto;
  background: snow!important;
}

#scrollable-auto-tabpanel-0 {
    background: snow !important;
}

#scrollable-auto-tabpanel-1 {
    background: snow !important;
}

#scrollable-auto-tabpanel-2 {
    background: snow!important;
}

#scrollable-auto-tabpanel-3 {
    background: snow !important;
}

#scrollable-auto-tabpanel-4 {
    background: snow !important;
}

#scrollable-auto-tabpanel-5 {
    background: snow !important;
}

.MuiTabs-flexContainer {
    flex-wrap: wrap;
}

.pricelist-mobile-title-wrapper {
    border-bottom: 1px solid #282828;
    margin: 0 2%;
    margin: 0px 30px 30px 30px;
  /* margin-top: 50px; */
  }

  .price-mobile-title {
    color: #282828;
  font-size: 30px;
  /* padding-bottom: 30px; */
  line-height: 28px;
  font-weight: 400;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 18px;
  width: 100%;
  }

  .price-item{
    margin-top:6px;
    font-size: 12px;

  }

  .itemlist-container {
      width:240px !important;
      padding-left: 0 !important;
  }


@media screen and (min-width: 768px){
    .pricelist-mobile-container{
        display:none;
    }

   
}