 
  /* ---------------------------------- */
  /* ----------- SECTION 6 ------------ */
  /* ---------------------------------- */
  #section6 {
    background: snow;
      /* background: #f9f9f9; */
      margin: 0 2%;
  }
  #section6 .container {
    max-width: 100%;
    
    padding: 0 !important;
    display: flex;
  }
  #section6 .grid {
    /* padding:2%; */
    
     flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
  }
  #section6 .col {
    border-radius: 2px;
    margin:5px;
    flex: 48%;
    
    position: relative;
    background: #1f1f1f;
    height: auto;
  }
  #section6 .col:hover {
    background: rgba(	1, 99, 121, 0.8);
  }
  #section6 img {
    width: 100%;
    height: 100%;   
    /* height: 73.65vh;  */
    display: block;  
    opacity: 0.3;
    filter: alpha(opacity=15);
  }

  #section6 .testText {
    position: absolute;
    top: 15%;
    left: 15%;
    right: 15%;
    bottom: 20%;
    opacity: 0;
    filter: alpha(opacity=0);
    color: #fff;
    
    -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
    
       display: -webkit-flex;
     display: -ms-flexbox;
     display: -webkit-box;
     display: flex;
     
     -webkit-flex-direction: row;
     -ms-flex-direction: row;
             -webkit-box-orient: horizontal;
             -webkit-box-direction: normal;
             flex-direction: row;
             
     -webkit-align-items: center;
     -ms-flex-align: center;
             -webkit-box-align: center;
             align-items: center;
             
     -webkit-justify-content: center;
     -ms-flex-pack: center;
             -webkit-box-pack: center;
             justify-content: center;
  }
  #section6 .testText .inner {
    position: relative;
    line-height: 1.4em;
  }
  #section6 .testText .inner:before {
    font-family: FontAwesome;
    font-size: 0.8em;
    position: absolute;
    top: -5px;
    left: -15px;
    color: #1b1b1b;
  }
  
  #section6 .col:hover .testText {
    top: 5%;
    opacity: 1;
    filter: alpha(opacity=100);
  }
  
  #section6 .testName {
    position: absolute;
    top: 80%;
    left: 5%;
    right: 5%;
    bottom: 5%;
  }
  #section6 h4 {
    font-size: 1em;
    line-height: 2em;
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
  }
  #section6 h4 strong {
    color:  rgb(	1, 99, 121);
  }
  #section6 .col:hover h4 strong {
    color: #1b1b1b;
  }


@media screen and (min-width: 768px) {
  .grid {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
  
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
  
    width: 100%;
  }
}

.title {
  color: #282828;
  font-size: 30px;
  /* text-align: center; */
  /* padding-bottom: 30px; */
  line-height: 22px;
  font-weight: 400;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 18px;
  width: 100%;
}

.team-title-wrapper {
  border-bottom: 1px solid #282828;
  margin: 30px;
margin-top: 50px;
}

.team-container {
  background: snow;
  /* background: #f9f9f9; */
height: 100vh;
padding-top:70px;
max-width: 100% !important;

}
.mobile-subtext {
  display:none !important;

}

@media screen and (max-width: 1100px) {
  .team-title-wrapper {
    margin: 0px 30px 30px 30px;
  }

  .mobile-subtext {
    display:block !important;

  }

  .team-container {
    height: auto;
    padding-top: 120px;
  }


};





  
