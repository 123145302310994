.slide {
 height: 100vh !important;
 width: 100% !important;
 object-fit: fill !important;
 position: relative !important;
}

.preload-images {
  display: none; 
  width: 0;
  height: 0;
  background: url('../../images/ImageOne.jpg'),
              url('../../images/ImageTwo.jpg'),
              url('../../images/ImageThree.jpg'),
              url('../../images/ImageFour.jpg');
}

.container {
    height:100vh !important;
   width:100% !important;
   z-index:1;
}

.slick-prev {
    left: 5% !important;
    position: absolute !important;
    z-index: 1;
  }

  .slick-next {
    right: 5% !important;
    position: absolute !important;
  }

@media screen and (max-width: 768px) {
    .slide {
        object-fit: cover !important;
       }
}